export enum RouteData {
  BREADCRUMB = 'breadcrumb',
  BREADCRUMB_ICON = 'breadcrumbIcon',
  BACK_TO_PARENT_BUTTON = 'backToParentButton',
  DASHBOARD_VIEW_TYPE = 'dashboardViewType',
  ALL_PORTFOLIOS = 'allPortfolios',
  SOURCE_VIEW = 'sourceView',
  DASHBOARD_SOURCE = 'dashboardSource',
  PROPERTY_IDS = 'propertyIds',
  PORTFOLIO_ID = 'portfolioId',
  BULK_SECTION_ID = 'bulkSectionId',
  TICKET_ID = 'ticketId',
  COMMENT_ID = 'commentId',
  KANBAN_SECTION = 'kanban-section',
  TURNOVER_OVERVIEW_STATE = 'turnover-overview-state',
  WORKFLOW_STEP = 'workflow-step',
  INSPECTIONS_VIEW = 'inspectionsView',
}
